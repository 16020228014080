import React, { useContext } from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";

import Container from "../components/Container";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";

import { Helmet } from "react-helmet";
import i18nContext from "../i18n-context";
import useLanguage from "../hooks/useLanguage";

const NotFoundPage = ({ data, location }) => {
  const classes = useStyles();
  const siteTitle = data.site.siteMetadata.title;
  const language = useLanguage();
  const i18n = useContext(i18nContext)[language];

  return (
    <Layout location={location} title={siteTitle}>
      <Helmet>
        <title>{`${i18n.websiteName} - ${i18n.notFound.title}`}</title>
      </Helmet>
      <Container className={classes.container}>
        <Typography variant="h2">{i18n.notFound.title}</Typography>
        <Typography variant="body1">
          {i18n.notFound.message}
        </Typography>
      </Container>
    </Layout>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    marginTop: theme.spacing(4),
  },
}));

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export default NotFoundPage;
